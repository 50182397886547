import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentDevice: {},
};

const DeviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDevice: (state, action) => {
      state.currentDevice = { ...state.currentDevice, ...action.payload };
    },
    resetDevice: (state) => {
      state.currentDevice = {};
    },
  },
});

export const { setDevice, resetDevice } = DeviceSlice.actions;
export default DeviceSlice.reducer;
