import React, { useRef, useState } from 'react';
import moment from 'moment';
import { DateRangePicker as RDRDateRangePicker } from 'react-date-range';
import { ko } from 'react-date-range/dist/locale';
import { Box, Button, IconButton, InputAdornment, Menu } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './index.css';
import CustomTextField from '../customTextField/index';
import { useTranslation } from 'react-i18next';

const smallSizeStyle = {
  '.rdrDefinedRangesWrapper': {
    width: 170,
    display: 'none',
  },
  // Additional custom styles here
};

const dateFormat = 'YYYY-MM-DD';
const DateRangePicker = ({ state, setState, sx, dateRangeProps, size, errorMessage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const calendarRef = useRef(null);

  const handleClick = (event) => {
    calendarRef.current.focus();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (ranges) => {
    setState(ranges.selection);
  };

  return (
    <Box>
      <CustomTextField
        onClick={handleClick}
        sx={{
          width: 280,
          '.MuiInputBase-root': {
            bgcolor: 'common.white',
          },
          '.MuiInputBase-input': {
            fontSize: 14,
            color: 'text.primary',
          },
          ...sx,
          '@media (max-width: 900px)': {
            width: 260,
          },
        }}
        errMg={errorMessage}
        value={`${moment(state.startDate).format(dateFormat)}    ~    ${moment(state.endDate).format(dateFormat)}`}
        placeholder={`${dateFormat}    ~    ${dateFormat}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton size="small" onClick={handleClick}>
                <CalendarMonthIcon />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
          inputRef: calendarRef,
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            bgcolor: 'transparent',
            border: 'none',
            boxShadow: 'none',
          },
        }}
      >
        <Box
          sx={{
            border: '1px solid #D3D3D3',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '6px',
            overflow: 'hidden',
            bgcolor: 'common.white',
            '.rdrDefinedRangesWrapper': {
              width: 170,
              display: 'none',
            },
            ...(size === 'small' && smallSizeStyle),
          }}
        >
          <RDRDateRangePicker
            dateDisplayFormat="yyyy-MM-dd"
            locale={ko}
            onChange={handleChange}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            weekStartsOn={1}
            showMonthArrow
            months={1}
            ranges={[state]}
            direction="horizontal"
            {...dateRangeProps}
          />
          {/* Action bar */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, borderTop: '1px solid #D3D3D3' }}>
            <Button onClick={handleClose} size="small" color="primary" sx={{ ml: 1 }}>
              {t('confirm')}
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default DateRangePicker;
