import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Box } from '@mui/material';
import L from 'leaflet';

// Fix default icon issue with Leaflet
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import { useTranslation } from 'react-i18next';

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

// Custom hook to update marker position on map drag
const MapEvents = ({ setMarkerPosition, onMapClick }) => {
  useMapEvents({
    drag: (e) => {
      const map = e.target;
      const center = map.getCenter();
      setMarkerPosition([center.lat, center.lng]);
      if (onMapClick) onMapClick(center.lat, center.lng);
    },
  });

  return null; // This component doesn't render anything
};

const FacilityMap = ({ initialMarkers, center, onMapClick }) => {

  const { t } = useTranslation();
  // Initialize marker position with the first marker in initialMarkers
  const [markerPosition, setMarkerPosition] = useState(initialMarkers[0].position);

  // Set the initial marker position when the component mounts
  useEffect(() => {
    setMarkerPosition(initialMarkers[0].position);
  }, [initialMarkers]);

  return (
    <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
      <MapContainer center={center} zoom={17} style={{ height: '100%', width: '100%' }} attributionControl={false}>
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Custom hook to handle map events */}
        <MapEvents setMarkerPosition={setMarkerPosition} onMapClick={onMapClick} />

        <Marker position={markerPosition}>
          <Popup>
            {t('lat')}: {markerPosition[0]} <br />
            {t('lng')}: {markerPosition[1]}
          </Popup>
        </Marker>
      </MapContainer>
    </Box>
  );
};

export default FacilityMap;
