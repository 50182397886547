

export const sensorElements = [
  { value: 'h2s', title: '황화수소', name: 'H2S (ppm)', description: '(계란썩는 냄새', bgColor: '#fff' },
  { value: 'nh3', title: '암모니아', name: 'NH3(ppm)', description: '(소변 냄새)', bgColor: '#fff' },
  { value: 'ch4', title: '메탄', name: 'CH4(LEL%)', description: '(무취)', bgColor: '#fff' },
  { value: 'windspeed', title: '', name: '풍속((m/s)', description: '', bgColor: 'rgb(214,217,224)' },
  { value: 'pm10', title: '미세먼지', name: 'PM10 (mg/m3)', description: '', bgColor: 'rgb(214,217,224)' },
  { value: 'pm25', title: '초미세먼지', name: 'PM2.5(mg/m3', description: '', bgColor: 'rgb(214,217,224)' },
  { value: 'temperature', title: '', name: '온도 (°C)', description: '', bgColor: 'rgb(214,217,224)' },
  { value: 'humidity', title: '', name: '습도(%)', description: '', bgColor: 'rgb(214,217,224)' },
];

export const floors = [1, 2, 3, 4];

export const sensors = [
  {
    sensorName: 'odor',
  },
  {
    sensorName: 'bacteria',
  },
  {
    sensorName: 'PM10',
  },
  {
    sensorName: 'TVOC',
  },
  {
    sensorName: 'HCHO',
  },
  {
    sensorName: 'H2S',
  },
  {
    sensorName: 'NH3',
  },
];
export const MapCenter = [37.394166, 126.63483];
export const MapMarkers = [{ position: [37.394166, 126.63483], popupText: '인천광역시 연수구' }];

function createData(id, device, status, level) {
  return { id, device, status: { status, level } };
}
export const floorDevicesData = [
  createData('01', 'Gate A-A', 0, 90),
  createData('02', 'Hall A’', 1, 100),
  createData('03', 'Gate B-A', 2, 70),
  createData('04', 'Hall C’', 1, 50),
  createData('05', 'Room Jupiter', 1, 50),
  createData('06', 'Center', 0, 80),
  createData('07', 'Gate A-A', 0, 90),
  createData('08', 'Hall A’', 1, 100),
  createData('09', 'Gate B-A', 2, 70),
  createData('10', 'Hall C’', 1, 50),
];

export const totalDevicesData = [
  createData('01', '1F - Gate A-A', 0, 90),
  createData('02', '3F - Hall A’', 1, 100),
  createData('03', '2F - Gate B-A', 2, 70),
  createData('04', '4F - Hall C’', 1, 50),
  createData('05', '3F - Room Jupiter', 1, 50),
  createData('06', '1F - Center', 0, 80),
  createData('07', '4F - Gate A-A', 0, 90),
  createData('08', '3F - Hall A’', 1, 100),
  createData('09', '4F - Gate B-A', 2, 70),
  createData('10', '1F - Hall C’', 1, 50),
  createData('11', '3F - Room Jupiter', 1, 50),
  createData('12', '2F - Center', 0, 80),
];

// test devices
export const devices = [
  {
    latestSensorInfo: {
      id: 1512,
      node_id: 1,
      presentation_time: '2024-10-16 11:48:32',
      h2s: 0,
      nh3: 0,
      ch4: 0,
      temperature: 25,
      humidity: 51,
      pm10: 27,
      winddirection: 0,
      windspeed: 0,
      pm25: 20,
      create_dttm: 1729046913000,
    },
    mac_address: '409CA737CB9F',
    latitude: '37.5665', // Seoul
    node_name: 'Seoul Device',
    CREATE_DTTM: 1728804263000,
    id: 1,
    radius: 40,
    node_id: 'NOD20241013162400',
    longitude: '126.9780',
  },
  {
    latestSensorInfo: {
      id: 1513,
      node_id: 2,
      presentation_time: '2024-10-16 11:55:12',
      h2s: 0,
      nh3: 1,
      ch4: 0.5,
      temperature: 24,
      humidity: 55,
      pm10: 22,
      winddirection: 1,
      windspeed: 2,
      pm25: 18,
      create_dttm: 1729047954000,
    },
    mac_address: '409CA737CB9E',
    latitude: '35.1796', // Busan
    node_name: 'Busan Device',
    CREATE_DTTM: 1728805263000,
    id: 2,
    radius: 40,
    node_id: 'NOD20241013162401',
    longitude: '129.0756',
  },
  {
    latestSensorInfo: {
      id: 1514,
      node_id: 3,
      presentation_time: '2024-10-16 12:03:45',
      h2s: 0.1,
      nh3: 0.2,
      ch4: 0.3,
      temperature: 23,
      humidity: 50,
      pm10: 30,
      winddirection: 2,
      windspeed: 1,
      pm25: 19,
      create_dttm: 1729048345000,
    },
    mac_address: '409CA737CB9D',
    latitude: '35.8714', // Daegu
    node_name: 'Daegu Device',
    CREATE_DTTM: 1728806263000,
    id: 3,
    radius: 40,
    node_id: 'NOD20241013162402',
    longitude: '128.6014',
  },
];
