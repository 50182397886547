import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Box } from '@mui/material';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';

// Fix default icon issue with Leaflet
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

// Component to update map view based on bounds
const ChangeView = ({ bounds }) => {
  const map = useMap();

  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds);
    }
  }, [map, bounds]);

  return null;
};

const TrendMap = ({ devices }) => {
  const { t } = useTranslation();

  // Ensure devices is an array and contains valid data
  if (!Array.isArray(devices) || devices.length === 0) {
    return <div>{t('no_devices')}</div>;
  }

  // Helper function to check if latitude and longitude are valid
  const isValidLatLng = (lat, lng) => !isNaN(lat) && !isNaN(lng);

  // Calculate positions and bounds based on valid devices
  const devicePositions = devices
    .map((device) => ({
      lat: parseFloat(device.latitude),
      lng: parseFloat(device.longitude),
      id: device.id,
      nodeName: device.node_name,
    }))
    .filter((device) => isValidLatLng(device.lat, device.lng)); // Filter out invalid positions

  const bounds =
    devicePositions.length > 0 ? L.latLngBounds(devicePositions.map((device) => [device.lat, device.lng])) : null; // Create bounds if there are valid positions

  // Use the first valid device's location as the initial center of the map
  const initialPosition = devicePositions.length > 0 ? [devicePositions[0].lat, devicePositions[0].lng] : [0, 0]; // Fallback to a default position if no devices

  return (
    <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
      <MapContainer
        center={initialPosition}
        zoom={13} // Adjusted the zoom level to start with a wider view
        style={{ height: '100%', width: '100%' }}
        attributionControl={false}
      >
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Render markers for each valid device */}
        {devicePositions.map((device) => (
          <Marker key={device.id} position={[device.lat, device.lng]}>
            <Popup>
              <strong>{t('device')}</strong>: <strong>{device.nodeName}</strong>
              <br />
              {t('lat')}: {device.lat} <br />
              {t('lng')}: {device.lng}
            </Popup>
          </Marker>
        ))}

        {/* Update the map's view to fit all markers */}
        {bounds && <ChangeView bounds={bounds} />}
      </MapContainer>
    </Box>
  );
};

export default TrendMap;
