import {
  imgCh4,
  imgH2s,
  imgNh3,
  imgTemperature,
  imgHumidity,
  imgPm10,
  imgPm25,
  imgWindDir,
  imgWindSpeed,
} from './../../assets';
export const sensors = [
  {
    sensorName: 'odor',
  },
  {
    sensorName: 'bacteria',
  },
  {
    sensorName: 'PM10',
  },
  {
    sensorName: 'TVOC',
  },
  {
    sensorName: 'HCHO',
  },
  {
    sensorName: 'H2S',
  },
  {
    sensorName: 'NH3',
  },
];
export const formatEventTime = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp);
    // Convert to Korean Standard Time (UTC+9)
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Seoul', // Set the time zone to KST
    };

    return date.toLocaleString('ko-KR', options);
  }
  return null; // Return null if timestamp is not provided
};

export const foulElements = [
  {
    img: imgNh3,
    value: 'nh3',
  },
  {
    img: imgCh4,
    value: 'ch4',
  },
  {
    img: imgH2s,
    value: 'h2s',
  },
];

export const complaintElements = [
  { value: 'temperature', img: imgTemperature },
  { value: 'windspeed', img: imgWindSpeed },
  { value: 'winddirection', img: imgWindDir },
  { value: 'pm10', img: imgPm10 },
  { value: 'pm25', img: imgPm25 },
  { value: 'humidity', img: imgHumidity },
];
