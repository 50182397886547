import { Grid, Typography } from '@mui/material';
import React from 'react';
import { fontStyle } from '../utils';
import { useTranslation } from 'react-i18next';

function AlarmsDisplay({ alarm }) {
  const { t } = useTranslation();
  return (
    <Grid sx={{ padding: '15px' }} item>
      <Typography sx={{ ...fontStyle }}>{alarm.alarmMain}</Typography>
      <Typography sx={{ ...fontStyle, fontSize: '0.9rem' }}>{alarm.alarmSecondary}</Typography>
    </Grid>
  );
}
export default AlarmsDisplay;
