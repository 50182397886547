import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Box } from '@mui/material';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { imgWindDir } from './../../../assets';
import DeviceMarker from './../../../assets/device_icon.png'; // Your custom device icon

// Define the custom device icon
const deviceIcon = new L.Icon({
  iconUrl: DeviceMarker,
  iconSize: [44, 44], // Set the size of the icon
  iconAnchor: [16, 32], // Anchor the icon so the tip points to the marker's location
  popupAnchor: [0, -32], // Set the popup's anchor
});

// Set default marker icon globally to the custom device icon
L.Icon.Default.mergeOptions({
  iconRetinaUrl: DeviceMarker,
  iconUrl: DeviceMarker,
  iconSize: [44, 44], // Customize size to match your custom icon
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

// Custom component to update map view
const ChangeView = ({ bounds }) => {
  const map = useMap();
  const [prevBounds, setPrevBounds] = useState(null); // Track previous bounds

  useEffect(() => {
    if (bounds && !bounds.equals(prevBounds)) {
      map.fitBounds(bounds);
      setPrevBounds(bounds); // Update previous bounds
    }
  }, [bounds, map, prevBounds]);

  return null;
};

// Custom Control component
const CustomControl = ({ windDirection }) => {
  const map = useMap();

  useEffect(() => {
    const control = L.control({ position: 'topright' });

    control.onAdd = () => {
      const div = L.DomUtil.create('div', 'info');
      div.innerHTML = `
      <div style="
        display: block;
        position: relative;
        background-color: #059c93;
        padding: 4px;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        overflow: hidden;
      ">
        <div style="
          position: relative;
          width: 160px;
          height: 60px;
          background-image: url(${imgWindDir});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        ">
          <span style="
            position: absolute;
            bottom: 16px;
            right: 20px;
            font-size: 30px;
            font-weight: 900;
            color: green;
          ">
            ${windDirection}
          </span>
        </div>
      </div>
    `;

      return div;
    };

    control.addTo(map);

    return () => {
      map.removeControl(control);
    };
  }, [map, windDirection]);

  return null;
};

const ComplaintDetailMap = ({ currentDevice }) => {
  const { t } = useTranslation();

  const complaintLatitude = parseFloat(currentDevice?.complaint_latitude);
  const complaintLongitude = parseFloat(currentDevice?.complaint_longitude);
  const nodeLatitude = parseFloat(currentDevice?.node_latitude);
  const nodeLongitude = parseFloat(currentDevice?.node_longitude);

  const isValidLatLng = (lat, lng) => !isNaN(lat) && !isNaN(lng);

  const complaintPosition = isValidLatLng(complaintLatitude, complaintLongitude)
    ? [complaintLatitude, complaintLongitude]
    : null;

  const nodePosition = isValidLatLng(nodeLatitude, nodeLongitude) ? [nodeLatitude, nodeLongitude] : null;

  const bounds = complaintPosition && nodePosition ? L.latLngBounds([complaintPosition, nodePosition]) : null;

  if (!complaintPosition || !nodePosition) {
    return <div>{t('invalid_coords')}</div>;
  }

  const windDirectionMap = {
    0: '↓',
    1: '↙',
    2: '←',
    3: '↖',
    4: '↑',
    5: '↗',
    6: '→',
    7: '↘',
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <MapContainer
        center={complaintPosition}
        zoom={17}
        style={{ height: '100%', width: '100%', position: 'relative' }}
        attributionControl={false}
      >
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
        />
        <ChangeView bounds={bounds} />

        {complaintPosition && (
          <Marker position={complaintPosition} icon={deviceIcon}>
            {' '}
            {/* Apply custom icon */}
            <Popup>
              <strong>{t('complaint_coords')}</strong>
              <br />
              {t('lat')}: {complaintPosition[0]} <br />
              {t('lng')}: {complaintPosition[1]}
            </Popup>
          </Marker>
        )}

        {nodePosition && (
          <Marker position={nodePosition} icon={deviceIcon}>
            {' '}
            {/* Apply custom icon */}
            <Popup>
              <strong>{t('device_coords')}</strong>
              <br />
              {t('lat')}: {nodePosition[0]} <br />
              {t('lng')}: {nodePosition[1]}
            </Popup>
          </Marker>
        )}

        <CustomControl windDirection={windDirectionMap[currentDevice?.winddirection || '0']} />
      </MapContainer>
    </Box>
  );
};

export default ComplaintDetailMap;
