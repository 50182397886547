import PropTypes from 'prop-types';
// material-ui
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// project import
import Dot from './@extended/Dot';
import { fontStyle } from '../pages/dashboard/utils';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getAuthToken } from '../utils/jwtUtils';
import { useSelector } from 'react-redux';
import { formatEventTime } from '../pages/odor-complaint/utils';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// ==============================|| Device TABLE - HEADER ||============================== //

function DevicesTableHead({ order, orderBy }) {
  const { t } = useTranslation();
  const headCells = [
    { id: 'id', label: t('no') },
    { id: 'node_name', label: t('device') },
    { id: 'create_dttm', label: t('date') },
    { id: 'latitude', label: t('lat') },
    { id: 'longitude', label: t('lng') },
    { id: 'status', label: t('status') },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ ...fontStyle, textWrap: 'nowrap' }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DevicesTableHead.propTypes = { order: PropTypes.any, orderBy: PropTypes.string };

function OrderStatus({ status }) {
  const { t } = useTranslation();
  let color;
  let title;

  switch (status) {
    case 0:
      color = 'warning';
      title = t('pending');
      break;
    case 1:
      color = 'success';
      title = t('completed');
      break;
    case 2:
      color = 'error';
      title = t('in_route');
      break;
    default:
      color = 'primary';
      title = t('none');
  }

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Dot color={color} />
      <Typography sx={{ ...fontStyle, fontWeight: 200, fontSize: '0.9rem' }}>{title}</Typography>
    </Stack>
  );
}

OrderStatus.propTypes = { status: PropTypes.number.isRequired };

// ==============================|| ORDER TABLE ||============================== //

export default function DevicesTable({ devices }) {
  const order = 'asc';
  const orderBy = 'id';
  const { t } = useTranslation();

  return (
    <Box sx={{ width: '100%', overflow: 'auto', minHeight: 400 }}>
      <TableContainer
        sx={{
          width: '100%',
          minHeight: 400,
          overflow: 'auto',
        }}
      >
        <Table stickyHeader aria-labelledby='table'>
          <DevicesTableHead order={order} orderBy={orderBy} />
          <TableBody>
            {stableSort(devices, getComparator(order, orderBy)).map((device, index) => (
              <TableRow
                hover
                role='checkbox'
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                tabIndex={-1}
                key={device.id} // Changed to use unique id
              >
                {/* ID */}
                <TableCell sx={{ ...fontStyle, fontWeight: 200, fontSize: '0.9rem', textWrap: 'nowrap' }}>
                  {device.id}
                </TableCell>

                {/* Node Name */}
                <TableCell sx={{ ...fontStyle, fontWeight: 200, fontSize: '0.9rem', textWrap: 'nowrap' }}>
                  {device.node_name}
                </TableCell>

                {/* Creation Date */}
                <TableCell sx={{ ...fontStyle, fontWeight: 200, fontSize: '0.9rem', textWrap: 'nowrap' }}>
                  {new Date(device.CREATE_DTTM).toLocaleString('ko-KR', {
                    timeZone: 'Asia/Seoul',
                    dateStyle: 'medium',
                  })}
                </TableCell>

                {/* Latitude */}
                <TableCell sx={{ ...fontStyle, fontWeight: 200, fontSize: '0.9rem', textWrap: 'nowrap' }}>
                  {device.latitude ? parseFloat(device.latitude).toFixed(6) : 'N/A'}
                </TableCell>

                {/* Longitude */}
                <TableCell sx={{ ...fontStyle, fontWeight: 200, fontSize: '0.9rem', textWrap: 'nowrap' }}>
                  {device.longitude ? parseFloat(device.longitude).toFixed(6) : 'N/A'}
                </TableCell>

                <TableCell
                  sx={{
                    ...fontStyle,
                    fontWeight: 200,
                    fontSize: '0.9rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    textWrap: 'nowrap',
                  }}
                >
                  <Dot /> {t('normal_device')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
