import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import FacilityMap from './FacilityMap';
import { MapCenter, MapMarkers } from '../pages/monitoring/utils';
import {
  Box,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider, MobileDatePicker, MobileTimePicker, TimePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import ReportConfirmPopup from '../pages/monitoring/components/ReportConfirmPopup';
import { useDispatch } from 'react-redux';
import { setAlert } from '../redux/alert/alertSlice';
import { getAuthToken } from '../utils/jwtUtils';
import { setDevice } from '../redux/device/deviceSlice';
import dayjs from 'dayjs';

//
dayjs.locale('ko');
// Function to round down to the nearest 5 minutes
const roundToNearestFiveMinutes = (date) => {
  const minutes = date.minute();
  const roundedMinutes = Math.floor(minutes / 5) * 5; // Round down to nearest 5
  return date.minute(roundedMinutes).second(0); // Set seconds to 0
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function OdorPopup({ openPopup, handleClose }) {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const token = getAuthToken();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [formData, setFormData] = useState({
    userId: currentUser?.id,
    eventDttm: roundToNearestFiveMinutes(dayjs()), // Use the rounded time here
    stinkLevel: '0',
  });
  // Use refs to hold latitude and longitude
  const latRef = useRef('');
  const lngRef = useRef('');

  const handleMapClick = (lat, lng) => {
    latRef.current = lat;
    lngRef.current = lng;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (newDate) => {
    setFormData((prevData) => ({
      ...prevData,
      eventDttm: newDate,
    }));
  };

  const isToday = dayjs(formData.eventDttm).isSame(dayjs(), 'day');

  const handleSubmit = async () => {
    const eventTime = dayjs(formData.eventDttm).format('YYYY-MM-DD HH:mm:ss');
    const formattedDate = dayjs(eventTime).add(9, 'hour').toISOString();

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/reportOdor`, {
        method: 'POST', // Use POST method
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...formData,
          eventDttm: formattedDate,
          latitude: latRef.current || MapCenter[0],
          longitude: lngRef.current || MapCenter[1],
        }),
      });

      if (!response.ok) {
        dispatch(setAlert({ type: 'error', message: '신고를 제출하는 중에 오류가 발생했습니다. 다시 시도해 주세요!' }));
        setOpenConfirm(false);
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setOpenConfirm(false);
      handleClose();
      dispatch(setAlert({ type: 'success', message: '신고가 성공적으로 접수되었습니다!' }));
      dispatch(setDevice()); // to refetch complaint list including newly created complaint
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const handleConfirmOpen = () => {
    setOpenConfirm(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    handleClose();
  };

  return (
    <React.Fragment>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={openPopup}>
        <ReportConfirmPopup open={openConfirm} handleConfirmClose={handleConfirmClose} handleSubmit={handleSubmit} />
        <DialogTitle id='customized-dialog-title'>{t('report_odor_smell')}</DialogTitle>
        <DialogContentText sx={{ fontSize: { xs: 13, sm: 15 }, mt: -2, mx: 2.5, pb: 1 }}>
          {t('move_map_odor_occurrence')}
        </DialogContentText>

        <DialogContent dividers>
          <Box sx={{ height: { xs: 230, sm: 300 } }}>
            <FacilityMap initialMarkers={MapMarkers} center={MapCenter} onMapClick={handleMapClick} />
          </Box>
          <Box sx={{ mt: 2 }} component='form' variant='standard'>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              size='small'
              name='email'
              value={currentUser?.username}
              id='email'
              label={t('userid')}
              variant='outlined'
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ko'>
              <MobileDatePicker
                sx={{ mt: 2, display: { xs: 'block', sm: 'none' } }}
                disableFuture
                label={t('select_date')}
                value={formData.eventDttm}
                onChange={handleDateChange}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
              <MobileTimePicker
                disableFuture={isToday}
                sx={{ mt: 2, display: { xs: 'block', sm: 'none' } }}
                label={t('select_time')}
                value={formData.eventDttm}
                onChange={handleDateChange}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
              <DatePicker
                disableFuture
                sx={{ mt: 2, display: { xs: 'none', sm: 'block' } }}
                label={t('select_date')}
                value={formData.eventDttm}
                onChange={handleDateChange}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
              <TimePicker
                disableFuture={isToday}
                sx={{ mt: 2, display: { xs: 'none', sm: 'block' } }}
                label={t('select_time')}
                value={formData.eventDttm}
                onChange={handleDateChange}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
            </LocalizationProvider>
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'start',
                gap: 1,
                mt: 0.3,
                mb: 0,
                width: { xs: '100%', sm: 440, md: 500 },
              }}
            >
              <FormLabel sx={{ minWidth: 'max-content', mt: 1 }} id='demo-row-radio-buttons-group-label'>
                {t('odor_degree')}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='stinkLevel'
                value={formData.stinkLevel}
                onChange={handleChange}
              >
                <FormControlLabel
                  value='0'
                  control={<Radio sx={{ '& .MuiSvgIcon-root': { color: '#4CAF50' } }} />}
                  label={t('moderate')}
                />
                <FormControlLabel
                  value='1'
                  control={<Radio sx={{ '& .MuiSvgIcon-root': { color: '#FF9800' } }} />}
                  label={t('unhealthy')}
                />
                <FormControlLabel
                  value='2'
                  control={<Radio sx={{ '& .MuiSvgIcon-root': { color: '#FF5722' } }} />}
                  label={t('very_unhealthy')}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type='button' variant='contained' onClick={handleConfirmOpen}>
            {t('report_odor')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
