import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InputAdornment, IconButton, Stack, Alert, CircularProgress } from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Logo from '../../Logo.png';
import { signInFailure, signInStart, signInSuccess } from '../../redux/user/userSlice';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import { openPopup } from '../../redux/popup/popupSlice';
import Spinner from '../../components/Spinner';
import { FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

//
dayjs.locale('ko');

function AuthRegister() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [isChecking, setIsChecking] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);

  const [errorMessage, setErrorMessages] = useState({
    name: '',
    email: '',
    username: '',
    password: '',
    passwordConfirm: '',
    phoneNumber: '',
    dateOfBirth: '',
    general: '',
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    password: '',
    passwordConfirm: '',
    remember: '',
    phoneNumber: '',
    incheonCitizen: '', // Default value set to 'N'
    gender: '',
    dateOfBirth: null,
  });

  const [showPassword, setShowPasswords] = useState({
    password: false,
    passwordConfirm: false,
  });

  const togglePasswordShow = (type) => {
    if (type === 'password') setShowPasswords((prev) => ({ ...prev, password: !prev.password }));
    if (type === 'passwordConfirm') setShowPasswords((prev) => ({ ...prev, passwordConfirm: !prev.passwordConfirm }));
  };

  const checkUsernameAvailability = async () => {
    if (!formData.username) return;
    setIsChecking(true);
    setErrorMessages({})
    try {
      // Replace with your actual API request
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/check-username?username=${encodeURIComponent(formData.username)}`);
      const data = await response.json();
      if (!response.ok) {
        switch (data.message) {
          case 'emptyUsernameMessage':
          case 'invalidUsernameMessage':
          case 'sizeUsernameMessage':
          case 'idExistMessage':
            setErrorMessages((prev) => ({ ...prev, username: data.message }));
            break;
          default:
            setErrorMessages((prev) => ({ ...prev, general: data.message }));
            break;
        }
        return;
      }

      setIsUsernameAvailable(data.available);
    } catch (error) {
      setIsUsernameAvailable(false);
    } finally {
      setIsChecking(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    if (name === 'username') {
      setIsUsernameAvailable(false)
    }
  };

  const handleDateChange = (newValue) => {
    setFormData({ ...formData, dateOfBirth: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(signInStart());
      setErrorMessages({});
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          username: formData.username,
          email: formData.email,
          phone: formData.phoneNumber,
          password: formData.password,
          passwordConfirm: formData.passwordConfirm,
          gender: formData.gender,
          dateOfBirth: formData.dateOfBirth ? formData.dateOfBirth.format('YYYY-MM-DD') : null,
          incheonCitizen: formData.incheonCitizen,
          agree: formData.agree ? 'Y' : 'N',
        }),
      });

      const data = await res.json();

      if (!res.ok) {
        dispatch(signInFailure(data.message || '등록 실패'));
        switch (data.message) {
          case 'emptyNameMessage':
          case 'invalidNameMessage':
          case 'sizeNameMessage':
            setErrorMessages((prev) => ({ ...prev, name: data.message }));
            break;

          case 'emptyUsernameMessage':
          case 'invalidUsernameMessage':
          case 'sizeUsernameMessage':
          case 'idExistMessage':
            setErrorMessages((prev) => ({ ...prev, username: data.message }));
            break;

          case 'emptyEmailMessage':
          case 'invalidEmailMessage':
          case 'sizeEmailMessage':
          case 'emailExistMessage':
            setErrorMessages((prev) => ({ ...prev, email: data.message }));
            break;

          case 'emptyPasswordMessage':
          case 'invalidPasswordMessage':
          case 'sizePasswordMessage':
            setErrorMessages((prev) => ({ ...prev, password: data.message }));
            break;

          case 'emptyPasswordConfirmMessage':
          case 'passAndConfirmPassNoMatchMessage':
            setErrorMessages((prev) => ({ ...prev, passwordConfirm: data.message }));
            break;

          case 'emptyPhoneMessage':
          case 'invalidPhoneMessage':
          case 'sizePhoneMessage':
            setErrorMessages((prev) => ({ ...prev, phoneNumber: data.message }));
            break;

          case 'emptyDateOfBirthMessage':
            setErrorMessages((prev) => ({ ...prev, dateOfBirth: data.message }));
            break;

          default:
            setErrorMessages((prev) => ({ ...prev, general: data.message }));
            break;
        }
        return;
      }

      dispatch(
        openPopup({
          title: '성공적으로 등록되었습니다!',
          message:
            '성공적으로 등록되었습니다! 저희가 귀하의 등록을 확인한 후 로그인할 수 있도록 이메일을 보내드리겠습니다!',
        })
      );
      navigate('/login');
      dispatch(signInSuccess());
    } catch (err) {
      dispatch(signInFailure(err.message));
    }
  };

  useEffect(() => {
    checkUsernameAvailability();
  }, [formData.username])

  return (
    <Box
      component='main'
      sx={{
        maxWidth: '100vw',
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          alignItems: 'center',
          maxWidth: 450,
          my: 4,
          px: 2,
          mx: 'auto',
        }}
      >
        {loading && <Spinner />}
        <Box
          component='img'
          sx={{
            height: 'auto',
            width: '100%',
          }}
          alt='Logo'
          src={Logo}
        />
        <Typography variant='h1' sx={{ my: 2, fontWeight: 'bold', fontSize: '1.1rem' }}>
          AI 악취 및 공기질 관리 시스템
        </Typography>

        <Typography level='body-sm'>세션 시작을 위해 가입 해주세요</Typography>
        {errorMessage.general && (
          <Stack sx={{ width: '100%', mt: 1 }}>
            <Alert severity='error'>{t(errorMessage.general)}</Alert>
          </Stack>
        )}
        <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            sx={{ marginY: 0.8 }}
            required
            fullWidth
            id='name'
            label='이름'
            type='text'
            name='name'
            error={!!errorMessage.name}
            value={formData.name}
            onChange={handleChange}
            autoFocus
            helperText={errorMessage.name ? t(errorMessage.name) : ''}
          />
          <Box sx={{ width: '100%', marginY: 0.8 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
              <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                <DatePicker
                  label='생년월일'
                  maxDate={dayjs()}
                  value={formData.dateOfBirth}
                  onChange={handleDateChange}
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      error: errorMessage.dateOfBirth ? true : false,
                      helperText: errorMessage.dateOfBirth ? t(errorMessage.dateOfBirth) : '',
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <TextField
            sx={{ marginY: 0.8 }}
            required
            fullWidth
            id="username"
            type="text"
            label="아이디"
            name="username"
            error={!!errorMessage.username}
            value={formData.username}
            onChange={handleChange}
            helperText={errorMessage.username ? t(errorMessage.username) : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isChecking ? (
                    <CircularProgress size={24} />
                  ) : (
                    isUsernameAvailable ? (
                      <CheckCircleIcon color="success" />
                    ) : null
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={{ marginY: 0.8 }}
            required
            fullWidth
            id='email'
            type='email'
            label='이메일 주소'
            name='email'
            error={!!errorMessage.email}
            value={formData.email}
            onChange={handleChange}
            helperText={errorMessage.email ? t(errorMessage.email) : ''}
          />
          <TextField
            sx={{ marginY: 0.8 }}
            required
            fullWidth
            id='phoneNumber'
            label='전화 번호'
            type='text'
            error={!!errorMessage.phoneNumber}
            name='phoneNumber'
            value={formData.phoneNumber}
            onChange={handleChange}
            helperText={errorMessage.phoneNumber ? t(errorMessage.phoneNumber) : ''}
          />

          <TextField
            sx={{ marginY: 0.8 }}
            required
            fullWidth
            name='password'
            label='비밀번호'
            id='password'
            error={!!errorMessage.password}
            value={formData.password}
            onChange={handleChange}
            helperText={errorMessage.password ? t(errorMessage.password) : ''}
            type={showPassword.password ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password' edge='end' onClick={() => togglePasswordShow('password')}>
                    {showPassword.password ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={{ marginY: 0.8 }}
            required
            fullWidth
            name='passwordConfirm'
            label='비밀번호 확인'
            error={!!errorMessage.passwordConfirm}
            id='passwordConfirm'
            value={formData.passwordConfirm}
            onChange={handleChange}
            helperText={errorMessage.passwordConfirm ? t(errorMessage.passwordConfirm) : ''}
            type={showPassword.passwordConfirm ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password'
                    edge='end'
                    onClick={() => togglePasswordShow('passwordConfirm')}
                  >
                    {showPassword.passwordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RadioGroup
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='gender'
            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            value={formData.gender}
            onChange={handleChange}
            slotProps={{
              textField: {
                required: true,
              },
            }}
          >
            <FormLabel id='demo-row-radio-buttons-group-label'>성별</FormLabel>
            <FormControlLabel required value='M' control={<Radio />} label='남성' />
            <FormControlLabel required value='F' control={<Radio />} label='여성' />
          </RadioGroup>

          <RadioGroup
            row
            name='incheonCitizen'
            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            value={formData.incheonCitizen}
            onChange={handleChange}
            slotProps={{
              textField: {
                required: true,
              },
            }}
          >
            <FormLabel>인천 시민이신가요?</FormLabel>
            <FormControlLabel required value='Y' control={<Radio />} label='예' />
            <FormControlLabel required value='N' control={<Radio />} label='아니요' />
          </RadioGroup>

          <FormGroup>
            <FormControlLabel
              name='agree'
              control={<Checkbox checked={formData.agree} onChange={handleChange} />}
              label='개인 정보 수집 및 이용에 동의합니다.'
              required
            />
          </FormGroup>

          <Link href='/login' variant='body2'>
            이미 계정이 있나요? 로그인
          </Link>

          <Button type='submit' fullWidth variant='contained' sx={{ mt: 1.5, borderRadius: '20px' }}>
            등록
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AuthRegister;
