import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';

export default function ReportConfirmPopup({ open, handleConfirmClose, handleSubmit }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog open={open} aria-labelledby='responsive-dialog-title'>
        <DialogContent>
          <DialogContentText>{t('would_you_like_to_report')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>{t('cancel')}</Button>
          <Button variant='contained' onClick={handleSubmit} autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
