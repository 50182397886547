import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SensorChart from '../components/SensorChart';
import { Box, Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper.css';
import { Navigation } from 'swiper/modules';
import MainCard from '../../../components/MainCard';
import { useTranslation } from 'react-i18next';
import { getAuthToken } from '../../../utils/jwtUtils';
import { sensorElements } from '../utils';
import moment from 'moment';
const breakpoints = {
  0: {
    slidesPerView: 1,
  },
  700: {
    slidesPerView: 2,
  },
  1024: {
    slidesPerView: 3,
  },
  1800: {
    slidesPerView: 5,
  },
  3000: {
    slidesPerView: 6,
  },
};

export default function DashboardCarousel({ data }) {
  const { t } = useTranslation();
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    const labels = [];
    const datasets = {};

    sensorElements.forEach((sensor) => {
      datasets[sensor.sensor.toLowerCase()] = {
        max: {
          label: t('max'),
          data: [],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          fill: false,
        },
      };
    });

    data.forEach((entry) => {
      const dateString = moment(`${entry.day} ${entry.hour}`, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm');
      if (!labels.includes(dateString)) {
        labels.push(dateString);
      }

      sensorElements.forEach((sensor) => {
        const sensorKey = sensor.sensor.toLowerCase();
        switch (sensorKey) {
          case 'temperature':
            datasets[sensorKey].max.data.push(entry['max_temperature']);
            break;
          case 'humidity':
            datasets[sensorKey].max.data.push(entry['max_humidity']);
            break;
          case 'pm10':
            datasets[sensorKey].max.data.push(entry['max_pm10']);
            break;
          case 'windspeed':
            datasets[sensorKey].max.data.push(entry['max_windspeed']);
            break;
          case 'pm25':
            datasets[sensorKey].max.data.push(entry['max_pm25']);
            break;
          default:
            break;
        }
      });
    });

    labels.sort();

    const formattedData = {};
    Object.keys(datasets).forEach((sensor) => {
      formattedData[sensor] = { labels, datasets: Object.values(datasets[sensor]) };
    });

    setGraphData(formattedData);
  }, [data]);

  return (
    <Swiper
      spaceBetween={10}
      breakpoints={breakpoints}
      loop={true}
      navigation={true}
      pagination={true}
      modules={[Navigation]}
    >
      {sensorElements.map((slide, index) => (
        <SwiperSlide key={index}>
          <MainCard>
            <Typography sx={{ textAlign: 'center' }} variant='h6'>
              {t(slide.sensor)}
            </Typography>
            {graphData[slide.sensor]?.labels.length > 1 ? (
              <SensorChart data={graphData[slide.sensor]} />
            ) : (
              <Box sx={{ height: 130, display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
                <Typography variant='body2' sx={{ textAlign: 'center', my: 'auto' }}>
                  {t('noData')}
                </Typography>
              </Box>
            )}
          </MainCard>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
