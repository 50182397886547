import React, { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// project import
import MainCard from './../../components/MainCard';
import OverviewBox from './components/OverviewBox';
import Calendar from '../../components/RDR';

import { dateActions, fontStyle, foulElements, testAlarms } from './utils';
import CustomSwiper from './swiper';
import DevicesTable from '../../components/DevicesTable';
import { useTranslation } from 'react-i18next';
import { Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getAuthToken } from '../../utils/jwtUtils';
import BacterialLevelChart from './components/BacterialLevelChart';
import AlarmsDisplay from './components/AlarmsDisplay';
import { addDays, addMonths } from 'date-fns';
import moment from 'moment';

// ==============================|| DASHBOARD - DEFAULT ||============================== //

function Dashboard() {
  const token = getAuthToken();
  const { t } = useTranslation();
  // Initial stats for max H2S, CH4, NH3
  const [overviewData, setOverviewData] = useState(null);
  const [devices, setDevices] = useState([]);
  const [sensorData, setSensorData] = useState([]);

  // Function to handle onChange for select field
  const [searchData, setSearchData] = useState({
    startDate: addMonths(new Date(), -1),
    endDate: new Date(),
    dateOption: dateActions.ONE_MONTH,
  });

  const handleDateChange = (e) => {
    const { value } = e.target;

    let startDate, endDate;

    switch (value) {
      case dateActions.TODAY:
        startDate = endDate = new Date();
        break;
      case dateActions.THREE_DAYS:
        startDate = addDays(new Date(), -3);
        endDate = new Date();
        break;
      case dateActions.ONE_MONTH:
        startDate = addMonths(new Date(), -1);
        endDate = new Date();
        break;
      case dateActions.THREE_MONTHS:
        startDate = addMonths(new Date(), -3);
        endDate = new Date();
        break;
      default:
        break;
    }

    setSearchData({ ...searchData, startDate, endDate, dateOption: value });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'searchDate' && value?.key === 'selection') {
      setSearchData({
        ...searchData,
        startDate: value?.startDate,
        endDate: value?.endDate,
        dateOption: '',
      });
    }
  };

  const fetchOverviewStats = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/sensorOverviewStats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate: moment(searchData.startDate).format('YYYY-MM-DD'),
          endDate: moment(searchData.endDate).format('YYYY-MM-DD'),
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data) {
        const updatedStats = {
          max_h2s: data.max_h2s,
          max_ch4: data.max_ch4,
          max_nh3: data.max_nh3,
        };

        setOverviewData(updatedStats);
      }
    } catch (error) {
      setOverviewData(null);
      return
    }
  };

  const fetchSensorsData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/sensorStats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate: moment(searchData.startDate).format('YYYY-MM-DD'),
          endDate: moment(searchData.endDate).format('YYYY-MM-DD'),
        }),
      });

      if (!res.ok) {
        setSensorData([]);
        return;
      }

      const data = await res.json();

      if (!data || data.length === 0) {
        setSensorData([]);
        return;
      }
      setSensorData(data)

    } catch (error) {
      setSensorData([]);
    }
  };

  const getDevices = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/nodeList`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        console.error('Error:', errorData);
        return null;
      }

      const data = await res.json();
      setDevices(data);
    } catch (error) {
      console.error('Fetch error:', error);
      return null;
    }
  };

  const handleSearch = () => {
    fetchOverviewStats();
    fetchSensorsData();
    getDevices();
  };

  useEffect(() => {
    fetchOverviewStats();
    fetchSensorsData();
    getDevices();
  }, []);

  return (
    <Grid container rowSpacing={2.5} columnSpacing={2.75} sx={{ mb: 1 }}>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            pb: 1.5,
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography
              sx={{
                borderRight: '0px !important',
                color: 'black',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
              }}
            >
              {t('period')}
            </Typography>

            <ToggleButtonGroup
              size='small'
              color='secondary'
              sx={{
                fontWeight: 'bold',
                display: 'block',
              }}
              value={searchData?.dateOption}
              exclusive
              onChange={handleDateChange}
              aria-label='defined date range'
            >
              {Object.keys(dateActions).map((key, index) => (
                <ToggleButton
                  key={`${index}-${dateActions[key]}`}
                  value={dateActions[key]}
                  aria-label='left aligned'
                  sx={{
                    color: 'black !important',
                    fontWeight: dateActions[key] === searchData.dateOption ? 'bold' : 400,
                    minWidth: '60px',
                    borderRadius: 0,
                    '&:focus': {
                      backgroundColor: 'lightblue',
                    },
                  }}
                >
                  {t(key)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Calendar
            state={{
              startDate: searchData.startDate,
              endDate: searchData.endDate,
              key: 'selection',
            }}
            dateRangeProps={{
              minDate: new Date('2017-08-01'),
              maxDate: new Date(),
            }}
            setState={(e) => handleChangeInput({ target: { name: 'searchDate', value: e } })}
          />

          {/* Btn */}
          <Button
            variant='contained'
            disabled={!searchData?.startDate || !searchData.endDate}
            onClick={handleSearch}
            sx={{
              width: 100,
              height: '100%',
              borderRadius: 3,
              m: 0,
            }}
          >
            {t('search')}
          </Button>
        </Box>
      </Grid>

      {/* Row for OverviewBoxes */}
      {foulElements.map((foul, id) => (
        <Grid key={id} item xs={12} sm={6} md={4}>
          <OverviewBox data={overviewData} foul={foul} />
        </Grid>
      ))}

      {/* Bacterial Line and */}
      <Grid item xs={12}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography sx={{ ...fontStyle }} variant='h5'>
              {t('odor_level')}
            </Typography>
          </Grid>
        </Grid>
        <MainCard sx={{ mt: 1.5 }} content={false}>
          <Box sx={{ pt: 1, pr: 2, height: '400px' }}>
            <BacterialLevelChart data={sensorData} slot={'day'} />
          </Box>
        </MainCard>
      </Grid>

      {/* Sensor Line Charts in swiper carousel */}
      <Grid item sx={{ mt: 1 }} xs={12}>
        <Grid item sx={{ mb: 1.5 }}>
          <Typography sx={{ ...fontStyle }} variant='h5'>
            {t('air_quality')}
          </Typography>
        </Grid>
        <CustomSwiper data={sensorData} />
      </Grid>

      {/* Devices Display */}
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography sx={{ ...fontStyle }} variant='h5'>
              {t('devices')}
            </Typography>
          </Grid>
        </Grid>

        <MainCard sx={{ mt: 2 }} content={false}>
          <DevicesTable devices={devices} />
        </MainCard>
      </Grid>

      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography sx={{ ...fontStyle }} variant='h5'>
              {t('alarms')}
            </Typography>
          </Grid>
        </Grid>
        <MainCard sx={{ mt: 2, overflowY: 'auto', height: '400px' }} content={false}>
          {testAlarms.map((alarm, alarmId) => (
            <AlarmsDisplay key={alarmId} alarm={alarm} />
          ))}
        </MainCard>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
