
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDevice } from '../../../redux/device/deviceSlice';
import { getAuthToken } from '../../../utils/jwtUtils';
import { formatEventTime } from '../utils';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Typography, Button } from '@mui/material';
import MainCard from '../../../components/MainCard';
import { Select, Option } from '@mui/joy';

const CustomPagination = ({ page, pageSize, totalRows, onPageChange, onPageSizeChange }) => {
  const totalPages = Math.ceil(totalRows / pageSize);
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="end" gap={2} p={2}>
      <Typography variant="body1">
        {`${t('page')} ${page} ${t('of')} ${totalPages}`}
      </Typography>
      <Box display="flex" gap={2} alignItems="center">
        <Select
          defaultValue={pageSize}
          onChange={(e, newValue) => onPageSizeChange(newValue)}
          size='small'
          sx={{ px: 1, py: 0.4, width: 70 }}
        >
          <Option sx={{ px: 1 }} value={8}>8</Option>
          <Option sx={{ px: 1 }} value={10}>10</Option>
          <Option sx={{ px: 1 }} value={20}>20</Option>
        </Select>
        <Button
          onClick={() => onPageChange(page - 1)}
          disabled={page <= 1}
          variant="outlined"
          size='small'
          sx={{
            borderRadius: 2,
            border: '1px solid gray',
            minWidth: '40px',
            textTransform: 'none',
            fontSize: '14px'
          }}
        >
          <KeyboardArrowLeftIcon style={{ color: '#000' }} />
        </Button>

        <Button
          variant="outlined"
          onClick={() => onPageChange(page + 1)}
          disabled={page >= totalPages}
          size='small'
          sx={{
            borderRadius: 2,
            border: '1px solid gray',
            minWidth: '40px',
            textTransform: 'none',
            fontSize: '14px',
          }}
        >
          <KeyboardArrowRightIcon style={{ color: '#000' }} />
        </Button>
      </Box>
    </Box >
  );
};

export default function ComplaintTable() {
  const { t } = useTranslation();
  const token = getAuthToken();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const { currentDevice } = useSelector((state) => state.device);
  const [complaints, setComplaints] = useState([]);
  const [selectedComplaintId, setSelectedComplaintId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [totalRows, setTotalRows] = useState(0);

  const getComplaints = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/complaintList?currentUserId=${currentUser?.id}&currentUserType=${currentUser?.userType}&page=${page}&size=${pageSize}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!res.ok) {
        const errorData = await res.json();
        console.error('Error:', errorData);
        return null;
      }

      const data = await res.json();
      setComplaints(data?.data);
      setTotalRows(data?.totalResults)
      setSelectedComplaintId(currentDevice?.complaint_id);
      if (data?.data?.length > 0 && !currentDevice?.complaint_id) {
        setSelectedComplaintId(data?.data[0].complaint_id);
        dispatch(setDevice(data?.data[0]));
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    getComplaints();
  }, [currentDevice, page]);

  const columns = [
    { field: 'id', headerName: t('no'), flex: 1 },
    { field: 'comLocation', headerName: t('comLocation'), flex: 1 },
    { field: 'compTime', headerName: t('compTime'), flex: 1.5 },
    { field: 'compLevel', headerName: t('compLevel'), flex: 1, },
    { field: 'compUser', headerName: t('compUser'), flex: 1 },
    { field: 'compPeriod', headerName: t('compPeriod'), flex: 1 },
    { field: 'status', headerName: t('status'), flex: 1 },
  ];

  const handleRowClick = (params, event) => {
    event.stopPropagation();
    setSelectedComplaintId(params.id);
    dispatch(setDevice(complaints.find((device) => device.complaint_id === params.id)));
  };

  return (
    <MainCard content={false}>
      <Typography sx={{ textAlign: 'center', mt: 2, fontWeight: 'bold', fontSize: 14 }} variant='body1'>
        {t('my_civil_complaint_submission_status')}
      </Typography>
      <Box sx={{ height: 340, width: '100%', overflowX: 'auto' }}>
        <DataGrid
          rows={complaints.map((complaint, index) => ({
            id: complaint.complaint_id,
            no: index + 1,
            comLocation: complaint.complaint_level === '1' ? '트라이볼' : complaint.complaint_level === '0' ? '그외' : '',
            compTime: formatEventTime(complaint.event_dttm),
            compLevel: complaint.stink_level === '0' ? '보통' : complaint.stink_level === '1' ? '심함' : complaint.stink_level === '2' ? '매우심함' : '',
            compUser: complaint.username_id,
            compPeriod: complaint.complaint_level,
            status: complaint.stink_status === '0' ? '확인전' : complaint.stink_status === '1' ? '처리중' : complaint.stink_status === '2' ? '완료' : '',
          }))}
          rowHeight={35}
          disableColumnMenu
          hideFooter
          columns={columns}
          onRowClick={handleRowClick}
          hideFooterSelectedRowCount
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowClassName={(params) => (params.id === selectedComplaintId ? 'selected-row' : '')}
          sx={{
            border: 'none',
            minWidth: 900,
            '& .selected-row': { backgroundColor: 'lightblue !important', },
            '.MuiDataGrid-columnHeaderTitle': { fontWeight: 600, cursor: 'pointer' }
          }}
        />

      </Box>
      <CustomPagination page={page} pageSize={pageSize} totalRows={totalRows} onPageChange={setPage} onPageSizeChange={setPageSize} />
    </MainCard>
  );
}
