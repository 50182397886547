import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { keyframes } from '@mui/system';

export const routes = [
  {
    to: '/',
    icon: <DashboardIcon />,
    text: 'dashboard',
  },
  {
    to: '/monitoring',
    icon: <BarChartIcon />,
    text: 'monitoring',
  },
  {
    to: '/odor',
    icon: <DeviceHubIcon />,
    text: 'odor-complaint',
  },
];

// Define the keyframes
export const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;
export const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;
