import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { fontStyle } from '../dashboard/utils';
import BacteriaMonitoring from './components/BacteriaMonitoring';
import MainCard from '../../components/MainCard';
import { useTranslation } from 'react-i18next';
import DevicesTable from '../../components/DevicesTable';
import TrendMap from './components/TrendMap';
import { getAuthToken } from '../../utils/jwtUtils';

function TrendPage() {
  const { t } = useTranslation();
  const token = getAuthToken();
  const [devices, setDevices] = useState([]);
  const getDevices = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/nodeList`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        console.error('Error:', errorData);
        return null;
      }

      const data = await res.json();
      setDevices(data);
    } catch (error) {
      console.error('Fetch error:', error);
      return null;
    }
  };

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <Box sx={{ display: { xs: 'block', lg: 'flex' }, gap: 2.5 }}>
      <Stack sx={{ width: { xs: '100%', lg: '50%' } }}>
        {/* MAP */}
        <MainCard>
          <Box sx={{ height: 400 }}>
            <TrendMap devices={devices} />
          </Box>
        </MainCard>
        {/* TABLE */}

        <Box>
          <Typography
            sx={{
              ...fontStyle,
              display: 'inline-block',
              lineHeight: '1',
              my: 2.5,
            }}
            variant='h5'
          >
            {t('complaint_filing_list')}
          </Typography>
          <MainCard content={false}>
            <DevicesTable devices={devices} />
          </MainCard>
        </Box>
      </Stack>

      <Stack sx={{ width: { xs: '100%', lg: '50%' } }}>
        <Typography
          sx={{
            ...fontStyle,
            lineHeight: '1',
            my: { xs: 2, lg: 1 },
          }}
          variant='h5'
        >
          {t('real_time_odor_value')}
        </Typography>
        <BacteriaMonitoring />
      </Stack>
    </Box>
  );
}

export default TrendPage;
