import React, { } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import MainCard from '../../components/MainCard';
import { useTranslation } from 'react-i18next';
import ComplaintDetail from './components/ComplaintDetail';
import ComplaintTable from './components/ComplaintTable';
import { useSelector } from 'react-redux';

function OdorComplaint() {
  const { t } = useTranslation();
  const { currentDevice } = useSelector((state) => state.device);
  const { currentUser } = useSelector((state) => state.user);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
      {/* TABLE */}
      <Box>
        <ComplaintTable />
      </Box>

      {currentUser.userType === 'U' && Object.keys(currentDevice).length > 0 && (
        <Stack sx={{ width: '100%' }}>
          {/* Details for User Type 'U' */}
          <MainCard content={false}>
            <Typography sx={{ textAlign: 'center', my: 1, fontWeight: 'bold' }} variant='body1'>
              {t('details')}
            </Typography>
            <ComplaintDetail />
          </MainCard>
        </Stack>
      )}

      {currentUser.userType === 'U' && Object.keys(currentDevice).length === 0 && (
        <>
          <Typography sx={{ mx: 'auto' }}>{t('no_complaint_header')}</Typography>
          <Typography sx={{ mx: 'auto' }}>{t('no_complaint_text')}</Typography>
        </>
      )}

      {currentUser.userType === 'A' && (
        <Stack>
          {/* Details for User Type 'A' */}
          <MainCard content={false}>
            <Typography sx={{ textAlign: 'center', my: 1, fontWeight: 'bold' }} variant='body1'>
              {t('details')}
            </Typography>
            <ComplaintDetail />
          </MainCard>
        </Stack>
      )}
    </Box>
  );
}

export default OdorComplaint;
