import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import questionReducer from "./question/questionSlice";
import answerReducer from "./answer/answerSlice";
import alertReducer from "./alert/alertSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import deviceReducer from './device/deviceSlice';
import popupReducer from './popup/popupSlice';

const rootReducer = combineReducers({ user: userReducer, question: questionReducer, answer: answerReducer, alert: alertReducer, device: deviceReducer, popup: popupReducer });
const persistConfig = {
  key: "root",
  storage,
  version: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);