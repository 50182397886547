import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment, IconButton, Stack, Alert } from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../Logo.png';
import { useNavigate } from 'react-router-dom';
import { signInFailure, signInStart, signInSuccess } from '../../redux/user/userSlice';
import { setAuthToken } from '../../utils/jwtUtils';
import { setAlert } from '../../redux/alert/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/Spinner';
import { useTranslation } from 'react-i18next';

const defaultTheme = createTheme();

function AuthLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const [showPassword, setShowPasswords] = useState(false);

  const togglePasswordShow = () => {
    setShowPasswords(!showPassword);
  };

  // Initialize formData with email from localStorage, if present
  const [formData, setFormData] = useState({
    username: localStorage.getItem('rememberUsername') || '',
    password: '',
    remember: localStorage.getItem('rememberUsername') ? true : false,
  });

  const [errorMessage, setErrorMessages] = useState({
    username: '',
    password: '',
    general: '',
  });

  // Function to handle onChange for text fields
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.remember) {
      localStorage.setItem('rememberUsername', formData.username);
    } else {
      localStorage.removeItem('rememberUsername');
    }

    try {
      dispatch(signInStart());
      setErrorMessages({});
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: formData.username, password: formData.password }),
      });

      const data = await res.json();

      if (!res.ok) {
        dispatch(signInFailure(data?.message));
        switch (data.message) {
          case 'idnoExistMessage':
            setErrorMessages((prev) => ({ ...prev, username: data.message }));
            break;

          case 'incorrectPasswordMessage':
            setErrorMessages((prev) => ({ ...prev, password: data.message }));
            break;
          default:
            setErrorMessages((prev) => ({ ...prev, general: data.message }));
            break;
        }
        return;
      }

      dispatch(signInSuccess(data));
      setAuthToken(data?.accessToken);
      dispatch(setAlert({ type: 'success', message: '사용자가 성공적으로 로그인했습니다!' }));
      navigate('/');
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: '문제가 발생했습니다. 올바른 사용자 아이디와 비밀번호로 다시 시도해 주세요!',
        })
      );
      dispatch(signInFailure(err.message));
    }
  };

  useEffect(() => {
    // If email is saved in localStorage, set it as default value for the email field
    const savedEmail = localStorage.getItem('rememberUsername');
    if (savedEmail) {
      setFormData((prevData) => ({
        ...prevData,
        email: savedEmail,
        remember: true,
      }));
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          {loading && <Spinner />}
          <Box
            component='img'
            sx={{
              height: 'auto',
              width: '100%',
            }}
            alt='Logo'
            src={Logo}
          />
          <Typography variant='h1' sx={{ my: 2, fontWeight: 'bold', fontSize: '1.1rem' }}>
            AI 악취 및 공기질 관리 시스템
          </Typography>

          <Typography level='body-sm'>세션 시작을 위해 로그인 해주세요</Typography>
          {errorMessage.general && (
            <Stack sx={{ width: '100%', mt: 1 }}>
              <Alert severity='error'>{t(errorMessage.general)}</Alert>
            </Stack>
          )}
          <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='username'
              label='아이디'
              name='username'
              error={!!errorMessage.username}
              value={formData.username}
              onChange={handleChange}
              autoFocus
              helperText={errorMessage.username ? t(errorMessage.username) : ''}
            />

            <Link href='/forgotPassword' variant='body2'>
              비밀번호를 잊으셨나요?
            </Link>

            <TextField
              sx={{ mt: 0.5 }}
              margin='normal'
              required
              fullWidth
              name='password'
              label='비밀번호'
              error={!!errorMessage.password}
              id='password'
              value={formData.password}
              onChange={handleChange}
              helperText={errorMessage.password ? t(errorMessage.password) : ''}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password' edge='end' onClick={togglePasswordShow}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid item>
                <Link href='/register' variant='body2'>
                  계정이 없나요? 가입하기
                </Link>
              </Grid>
              <FormControlLabel
                control={
                  <Checkbox name='remember' color='primary' checked={formData.remember} onChange={handleChange} />
                }
                label='아이디 기억하기'
              />
            </Grid>
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2, borderRadius: '20px' }}>
              로그인
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AuthLogin;
