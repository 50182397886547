import {
  Box,
  Button,
  FormControl,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MainCard from '../../../components/MainCard';
import { useTranslation } from 'react-i18next';
import ComplaintDetailMap from './ComplaintDetailMap';
import BacteriaAnalyzeColors from './BacteriaAnalayzeColors';
import { fontStyle } from '../../dashboard/utils';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthToken } from '../../../utils/jwtUtils';
import { setDevice } from '../../../redux/device/deviceSlice';
import { setAlert } from '../../../redux/alert/alertSlice';
import { complaintElements, formatEventTime, foulElements } from '../utils';

function ComplaintDetail() {
  const token = getAuthToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentDevice } = useSelector((state) => state.device);
  const { currentUser } = useSelector((state) => state.user);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const [updatebleForm, setUpdatebleForm] = useState({
    status: currentDevice?.stink_status,
    comment: currentDevice?.comment || '',
  });

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/updateComplaint/${currentDevice?.complaint_id}?currentUserId=${currentUser?.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            file_id: currentDevice?.file_id,
            comment: updatebleForm.comment,
            stink_status: updatebleForm?.status,
          }),
        }
      );
      // Handle non-OK responses
      if (!response.ok) {
        const errorData = await response.json();
        dispatch(
          setAlert({
            type: 'error',
            message: '불만 업데이트에 실패했습니다. 다시 시도해 주세요!',
          })
        );
        throw new Error(errorData.message || 'Failed to update complaint');
      }

      // Parse the response (if you expect a JSON response)
      const updatedComplaint = await response.json();
      dispatch(setDevice(updatedComplaint));
      dispatch(
        setAlert({
          type: 'success',
          message: '불만이 성공적으로 업데이트되었습니다!',
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          type: 'error',
          message: '불만 업데이트에 실패했습니다. 다시 시도해 주세요!',
        })
      );
    }
  };

  const handleUpdatableFormChange = (e) => {
    const { name, value } = e.target;
    setUpdatebleForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      // Read the file as a data URL if needed (not necessary for upload)
      reader.onloadend = () => {
        setUploadedImage(reader.result); // Save the image URL in state if needed
      };

      reader.readAsDataURL(file);

      // Prepare the FormData to send in the request
      const formData = new FormData();
      formData.append('file', file, file.name); // Append the file to FormData

      try {
        const response = await fetch('https://ifez.moitech.kr/ai/analyze/', {
          method: 'POST',
          headers: {
            accept: 'application/json',
            // Content-Type is set automatically when using FormData
          },
          body: formData, // Attach the FormData to the request body
        });

        // Check for successful response
        if (!response.ok) {
          dispatch(
            setAlert({
              type: 'error',
              message: '이미지를 성공적으로 업로드하지 못했습니다. 다시 시도해 주세요!',
            })
          );
          throw new Error('Network response was not ok ' + response.statusText);
        }

        const data = await response.json(); // Parse JSON response

        // Now, send a POST request to your server with the received data
        const insertResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/insertFileData`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data), // Send the data as a JSON string
        });

        // Check for successful response from your server
        if (!insertResponse.ok) {
          dispatch(
            setAlert({
              type: 'error',
              message: '이미지를 성공적으로 업로드하지 못했습니다. 다시 시도해 주세요!',
            })
          );
          throw new Error('Network response was not ok ' + insertResponse.statusText);
        }

        const insertData = await insertResponse.json(); // Parse the JSON response from your server

        // Update currentDevice with new file_id
        dispatch(setDevice({ file_id: insertData?.data?.id }));

        // Set flag to true after successful image upload
        setIsImageUploaded(true);

        dispatch(
          setAlert({
            type: 'success',
            message: '이미지가 성공적으로 업로드되었습니다!',
          })
        );
      } catch (error) {
        dispatch(
          setAlert({
            type: 'error',
            message: '이미지를 성공적으로 업로드하지 못했습니다. 다시 시도해 주세요!',
          })
        );
      }
    }
  };

  useEffect(() => {
    if (currentDevice) {
      setUpdatebleForm({
        status: currentDevice?.stink_status,
        comment: currentDevice?.comment || '',
      });
    }
  }, [currentDevice]);

  useEffect(() => {
    if (isImageUploaded && currentDevice?.file_id) {
      handleUpdate();
      setIsImageUploaded(false); // Reset the flag
    }
  }, [isImageUploaded, currentDevice?.file_id]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        height: '100%',
      }}
    >
      {/* Map Component */}
      <Box sx={{ flex: '1 1 auto', width: { xs: '100%', md: '33.3%' } }}>
        <MainCard sx={{ height: '100%' }}>
          <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant='body1'>
            {t('location')}
          </Typography>
          <Box sx={{ height: { xs: 340, md: 455 }, my: 1 }}>
            <ComplaintDetailMap currentDevice={currentDevice} />
          </Box>
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: { xs: 14, sm: 16 },
            }}
            variant='body1'
          >
            {formatEventTime(currentDevice?.event_dttm)}
          </Typography>
        </MainCard>
      </Box>
      {/* Detail Component */}
      <Box sx={{ flex: '1 1 auto', width: { xs: '100%', md: '33.3%' } }}>
        <MainCard sx={{ height: '100%' }}>
          <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant='body1'>
            {t('odor_info')}
          </Typography>
          <Box
            sx={{
              my: 1,
              display: 'flex',
              gap: 0.5,
              alignItems: 'start',
              backgroundColor: '#059c93',
              borderRadius: 1,
              p: 0.5,
            }}
          >
            {foulElements.map((el, id) => (
              // Display Image as Background
              <Box
                key={id} // Unique key for each element
                sx={{
                  height: { xs: 50, sm: 100, md: 65 },
                  width: '100%',
                  position: 'relative',
                  backgroundImage: `url(${el.img})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant='h5'
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: { xs: 0, sm: 10, md: 16 },
                    transform: 'translateY(-50%)',
                    color: '#000',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: { xs: 14, sm: 16 },
                  }}
                >
                  {currentDevice?.[el.value]}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              mt: 2,
              height: 'auto',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <MainCard
              sx={{
                width: { xs: '100%', sm: '40%' },
                height: 260,
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                py: 1,
              }}
              content={false}
            >
              <Typography
                sx={{
                  ...fontStyle,
                  fontSize: '0.8rem',
                  fontWeight: 'bold',
                }}
                variant='h5'
              >
                I-AQI
              </Typography>
              <Typography sx={{ ...fontStyle, fontSize: '1rem' }} variant='h5'>
                Bacteria Level
              </Typography>
              <Typography sx={{ ...fontStyle, fontSize: '0.8rem', color: 'gray' }} variant='h5'>
                {currentDevice?.i_aqi ?? '-'}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <BacteriaAnalyzeColors aqiValue={currentDevice?.i_aqi} />
              </Box>
            </MainCard>
            <MainCard
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: { xs: 'auto', sm: 260 },
                width: { xs: '100%', sm: '60%' },
              }}
              content={false}
            >
              {currentDevice?.file_id && (
                <ImageListItem sx={{ overflow: 'hidden', width: '100%' }}>
                  <img
                    src={currentDevice?.analyzed_image_path}
                    loading='lazy'
                    style={{ width: '100%', height: '100%' }}
                  />
                </ImageListItem>
              )}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 1,
                  gap: 1,
                  my: 'auto',
                }}
              >
                <Box>
                  <Typography sx={{ ...fontStyle, fontWeight: 'bold', fontSize: 12 }} variant='body1'>
                    Real
                  </Typography>
                  <Typography sx={{ ...fontStyle, fontSize: 12 }} variant='body1'>
                    Bacteria Count
                  </Typography>
                </Box>
                <Typography sx={{ ...fontStyle, fontSize: 12 }} variant='body1'>
                  {currentDevice?.cfu ?? '-'}
                  <Typography sx={{ fontSize: 11 }} component={'span'}>
                    &nbsp; CFU
                  </Typography>
                </Typography>
              </Box>
            </MainCard>
          </Box>
          {currentUser?.userType === 'A' && (
            <Box
              sx={{
                width: '25%',
                height: 6,
                display: 'block',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '2px none gray',
                borderRadius: 2,
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.0)',
                },
              }}
              onClick={() => document.getElementById('image-upload-input').click()}
            >
              <input
                type='file'
                id='image-upload-input'
                accept='image/*'
                style={{ display: 'none' }}
                onChange={(e) => handleImageUpload(e)}
              />
            </Box>
          )}

          <Box sx={{ my: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {complaintElements.map((el, id) => (
              <Box
                key={id}
                sx={{
                  display: 'block',
                  backgroundColor: '#059c93',
                  borderRadius: 1,
                  flex: {
                    xs: '1 1 calc(50% - 16px)',
                    md: '1 1 calc(33.33% - 16px)',
                  },
                  p: 0.5,
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                {/* Display Image as Background */}
                <Box
                  sx={{
                    height: 55, // You can adjust the height as needed
                    width: '100%',
                    position: 'relative',
                    backgroundImage: `url(${el.img})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    variant='h5'
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      fontSize: 16,
                      right: { xs: 0, sm: 10, md: 16 },
                      transform: 'translateY(-50%)',
                      color: '#000',
                      padding: '4px 8px',
                      borderRadius: '4px',
                    }}
                  >
                    {currentDevice?.[el.value]}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </MainCard>
      </Box>
      {/* Update Form Component */}
      <Box sx={{ flex: '1 1 auto', width: { xs: '100%', md: '33.3%' } }}>
        <MainCard
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ my: 0.5, textAlign: 'center', fontWeight: 'bold' }} variant='body1'>
            {t('analyze')}
          </Typography>
          <Box>
            {currentUser?.userType === 'A' && (
              <FormControl fullWidth sx={{ my: 1 }}>
                <InputLabel id='status-label'>{t('status')}</InputLabel>
                <Select
                  label={t('status')}
                  fullWidth
                  name='status'
                  value={updatebleForm.status} // Use the status from the state
                  onChange={handleUpdatableFormChange} // Use the existing handler
                >
                  <MenuItem value={0}>확인전</MenuItem>
                  <MenuItem value={1}>처리중</MenuItem>
                  <MenuItem value={2}>완료</MenuItem>
                </Select>
              </FormControl>
            )}

            <TextField
              label={t('analyze')}
              name='comment'
              placeholder={currentUser?.userType === 'A' ? 'Type here ...' : ''}
              inputProps={{ readOnly: currentUser?.userType === 'U' }}
              value={updatebleForm.comment}
              onChange={handleUpdatableFormChange}
              variant='outlined'
              fullWidth
              multiline
              minRows={13}
              sx={{ my: 1 }}
            />
            {currentUser?.userType === 'A' && (
              <Button
                variant='contained'
                sx={{
                  height: 'min-content',
                  borderRadius: 3,
                  my: 1,
                  width: '100%',
                }}
                onClick={handleUpdate}
              >
                {t('update')}
              </Button>
            )}
          </Box>
        </MainCard>
      </Box>
    </Box>
  );
}

export default ComplaintDetail;
