import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import MainCard from '../../../components/MainCard';
import { fontStyle } from '../utils';
import { useTranslation } from 'react-i18next';

export default function OverviewBox({ data, foul }) {
  const { t } = useTranslation();

  // Show a message while data is being fetched
  if (!data) {
    return (
      <MainCard >
        <Typography variant="h6" color="text.secondary">
          {t('pending')}...
        </Typography>
      </MainCard>
    );
  }

  return (
    <MainCard contentSX={{ p: 2.25 }}>
      <Typography sx={{ ...fontStyle, fontSize: 13, textAlign: 'center' }} variant="body1" color="text.secondary">
        {foul.header}
      </Typography>
      <Typography sx={{ ...fontStyle, fontSize: 13, textAlign: 'center' }} variant="body1" color="text.secondary">
        {foul.title}
      </Typography>
      <Typography sx={{ ...fontStyle, fontSize: 13, textAlign: 'center' }} variant="body1" color="text.secondary">
        {foul.name}
      </Typography>
      <Typography variant="h4" sx={{ ...fontStyle, fontSize: 14, textAlign: 'center' }}>
        {data[foul.value] ?? "-"}
      </Typography>
      <Typography sx={{ ...fontStyle, fontSize: 13, textAlign: 'center' }} variant="body1" color="text.secondary">
        {foul.description}
      </Typography>
    </MainCard>
  );
}

OverviewBox.propTypes = {
  foul: PropTypes.shape({
    value: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};
