import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MainCard from '../../../components/MainCard';
import { fontStyle } from '../../dashboard/utils';
import { useTranslation } from 'react-i18next';
import { getAuthToken } from '../../../utils/jwtUtils';
import { sensorElements } from '../utils';

// Reorder function for drag-and-drop
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function BacteriaMonitoring() {
  const { t } = useTranslation();
  const token = getAuthToken();
  const [devices, setDevices] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newDevices = reorder(deviceData, result.source.index, result.destination.index);
    setDeviceData(newDevices);
  };

  const getDevices = async () => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/nodeList`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        console.error('Error:', errorData);
        return null;
      }

      const data = await res.json();
      setDevices(data);
      setLoading(false); // Set loading to false when fetching is complete
    } catch (error) {
      console.error('Fetch error:', error);
      setLoading(false); // Set loading to false on error
      return null;
    }
  };

  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    setDeviceData(devices); // Update deviceData when devices change
  }, [devices]);

  // Loading UI
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable' type='ROW'>
        {(provided) => (
          <Stack
            sx={{ maxHeight: { xs: '700px', md: '967px' }, overflowY: 'auto' }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {deviceData.map((device, i) => (
              <Draggable key={device.id} draggableId={`device-${i}`} index={i}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: 2,
                      mb: i === deviceData.length - 1 ? 0 : 2,
                      bgcolor: '#e0e0e0',
                    }}
                  >
                    <Box sx={{ ...fontStyle, fontSize: '0.9rem', mt: 0.8, mb: 0.6, mx: 1 }}>
                      {t('device')}: {device.node_name}
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'flex' }, gap: 2 }}>
                      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                        {sensorElements.map((sensor, i) => {
                          return (
                            <MainCard
                              key={i}
                              sx={{
                                flex: '1 1 25%',
                                boxSizing: 'border-box',
                                height: 120,
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: sensor?.bgColor,
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                py: 2.5,
                              }}
                              content={false}
                            >
                              <Typography
                                sx={{
                                  ...fontStyle,
                                  fontSize: '0.8rem',
                                  fontWeight: 'bold',
                                  color: '#000',
                                }}
                                variant='h5'
                              >
                                {sensor?.title}
                              </Typography>
                              <Typography
                                sx={{
                                  ...fontStyle,
                                  fontSize: '0.8rem',
                                  fontWeight: 'bold',
                                  color: '#000',
                                }}
                                variant='h5'
                              >
                                {sensor?.name}
                              </Typography>
                              <Typography sx={{ ...fontStyle, fontSize: '1.2rem', color: '#000' }} variant='h5'>
                                {device.latestSensorInfo[sensor?.value] ?? '-'}
                              </Typography>
                              <Typography
                                sx={{
                                  ...fontStyle,
                                  fontSize: '0.8rem',
                                  fontWeight: 'bold',
                                  color: '#000',
                                }}
                                variant='h5'
                              >
                                {sensor?.description}
                              </Typography>
                            </MainCard>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default BacteriaMonitoring;
