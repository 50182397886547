import React from 'react';
import { Box } from '@mui/material';

// Adjust the AQI color mappings based on the image provided
const aqiGrades = [
  { max: 200, color: '#ADDA4B' },      // Grade 1 (Greenish)
  { max: 400, color: '#FFD700' },      // Grade 2 (Yellow)
  { max: 800, color: '#FF8C00' },      // Grade 3 (Orange)
  { max: 1600, color: '#FF4500' },     // Grade 4 (Red)
  { max: 2000, color: '#9370DB' },     // Grade 5 (Purple)
  { max: 2427, color: '#8B3A62' }      // Grade 6 (Dark Pink/Brown)
];

function BacteriaAnalyzeColors({ aqiValue }) {
  // Convert aqiValue to an integer
  const aqiInt = parseInt(aqiValue, 10);

  // Determine the number of boxes to show based on AQI value
  const activeGradeIndex = aqiGrades.findIndex(grade => aqiInt <= grade.max) + 1;

  return (
    <Box
      sx={{
        display: 'flex',
        width: 'max-content',
        gap: 0.2,
        mt: 1,
      }}
    >
      {aqiGrades.map((grade, index) => (
        <Box
          key={index}
          sx={{
            bgcolor: index < activeGradeIndex ? grade.color : 'rgba(211, 211, 211, 1)', // Active grades get color, others are gray
            py: 0.6,
            borderRadius: 0.7,
            width: '1.2rem'
          }}
        />
      ))}
    </Box>
  );
}

export default BacteriaAnalyzeColors;
